import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import { ThemeProvider } from 'styled-components';
import Menu from '@components/layout/Menu.json';
import Head from '@rsc/ui-components/Head';
import PageWrapper from '@rsc/ui-components/page-wrapper';
import Header from '@components/header';
import Footer from '@components/footer';
import theme from '@components/theme';
import { GlobalStyles } from '../utils/GlobalStyles';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'lite-youtube-embed/src/lite-yt-embed.css';
import { InitInsights } from '@rsc/utils';

InitInsights();

function Layout({ children, scriptsData, fullWidth }) {
  const scripts = [
    <script
      src="https://kit.fontawesome.com/4dd77ed7cb.js"
      key="fontawesome"
      async
    />,
    <script defer key="mavenoid">
      {`
        (function (m, a, v, e, n, o, i, d) {
          d = m.createElement(a);
          d.async = true;
          d.src =    "https://widget-hosts.mavenoid.com/custom-embedding-scripts/" + n + ".js";
          i = m.getElementsByTagName(a)[0];
          i.parentNode.insertBefore(d, i);
          v[e] = v[e] || [];
        })(document, "script", window, "mavenoid", "radio-systems-corp");
      `}
    </script>,
    ...scriptsData,
  ];

  return (
    <ThemeProvider theme={theme}>
      <Head scriptTags={scripts} />
      <GlobalStyles />
      <Header
        menuStructure={Menu}
        searchIndexes={['petsafe-support-articles', 'petsafe-support-pages']}
      />
      <PageWrapper fullWidth={fullWidth} background="inherit" padding="0">
        <main style={{ minHeight: '64.4vh' }}>{children}</main>
      </PageWrapper>
      <LazyLoad offset={100}>
        <Footer />
      </LazyLoad>
    </ThemeProvider>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  scriptsData: PropTypes.arrayOf(PropTypes.shape({})),
};

Layout.defaultProps = {
  fullWidth: false,
  scriptsData: [],
};

export default Layout;
